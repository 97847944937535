export function getTrophyDescription(trophyType, battleTitle = "") {
  if (trophyType === "finalShowdown_Trophy_Wonderful") {
    return (
      "Fought in the " + battleTitle + ", and emerged in the Wonderful 128"
    );
  } else if (trophyType === "finalShowdown_Trophy_Sensational") {
    return (
      "Fought in the " + battleTitle + ", and emerged in the Sensational 64"
    );
  } else if (trophyType === "finalShowdown_Trophy_Thrilling") {
    return "Fought in the " + battleTitle + ", and emerged in the Thrilling 32";
  } else if (trophyType === "finalShowdown_Trophy_Sweet") {
    return "Fought in the " + battleTitle + ", and emerged in the Sweet 16";
  } else if (trophyType === "finalShowdown_Trophy_Elite") {
    return "Fought in the " + battleTitle + ", and emerged in the Elite Eight";
  } else if (trophyType === "finalShowdown_Trophy_Ferocious") {
    return (
      "Fought in the " + battleTitle + ", and emerged in the Ferocious Four"
    );
  } else if (trophyType === "runnerUp_Trophy") {
    return "Declared a Runner-Up in the " + battleTitle;
  } else if (trophyType === "runnerUp_Sword") {
    return "Always a bridesmaid, never a bride.";
  } else if (trophyType === "admin") {
    return "Admin of Writing Battle";
  } else if (trophyType === "feedbackPanda") {
    return "Feedback Panda. Rarely seen in the wild.";
  } else if (trophyType === "scavengerHunt") {
    return "Dominated the " + battleTitle + " Scavenger Hunt!";
  } else if (trophyType === "noRedraw_Gold") {
    return "Who needs to redraw when you're clearly a magical unicorn?";
  } else if (trophyType === "noRedraw_Silver") {
    return "Huh!? What's a redraw?";
  } else if (trophyType === "noRedraw_Purple") {
    if (battleTitle) {
      return "No redraws used in the " + battleTitle;
    } else {
      return "No redraws used in a Battle";
    }
  } else if (trophyType === "gift_Purple") {
    return "Gifted a Battle to a friend!";
  } else if (trophyType === "gift_Silver") {
    return "I work part time as a gift-giving Elf...";
  } else if (trophyType === "gift_Gold") {
    return "Writer in the streets, Santa in the sheets (I gift a lot of Battles to my friends)";
  } else if (trophyType === "allRedraws_Gold") {
    return "What good is a redraw button if it can't be pushed?";
  } else if (trophyType === "allRedraws_Silver") {
    return "I like pushing buttons... specifically the redraw variety.";
  } else if (trophyType === "allRedraws_Purple") {
    if (battleTitle) {
      return "Used up all redraws in the " + battleTitle;
    } else {
      return "Used up all redraws in a Battle";
    }
  } else if (trophyType === "winner_Axe") {
    return "The Champ.";
  } else if (trophyType === "finalShowdown_Axe") {
    return "Slayer in the Final Showdown";
  } else if (trophyType === "participant_Axe") {
    return "A heedless warrior";
  } else if (trophyType === "winner_Sword") {
    return "Declared a Winner many a time";
  } else if (trophyType === "finalShowdown_Sword") {
    return "Fought in the Final Showdown many a time";
  } else if (trophyType === "participant_Sword") {
    return "Tirelessly battled many a time";
  } else if (trophyType === "winner_Trophy") {
    return "Declared a Winner of the " + battleTitle;
  } else if (trophyType === "finalShowdown_Trophy") {
    return (
      "Fought in the " + battleTitle + ", and emerged in the Final Showdown"
    );
  } else if (trophyType === "participant_Trophy") {
    return "Courageously entered the " + battleTitle;
  } else if (trophyType === "participant_Trophy_HM") {
    return "Honourable Mention in the " + battleTitle;
  } else if (trophyType === "OG") {
    return "Straight-up OG. Witnessed the contest's inception and helped shape its future";
  } else if (trophyType === "music") {
    return "Wrote and performed a dope song about Writing Battle";
  } else if (trophyType === "MVW") {
    // note: battleTitle will be the year
    return "Most Valuable Writer of " + battleTitle;
  } else {
    return "";
  }
}
