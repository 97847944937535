import { useEffect, useState } from "react";
import BattleCard from "./BattleCard";
import DrawPromptsControls from "./DrawPromptsControls";
import CardState from "../../publicFunctions/clientClasses/CardState";
import { redrawCards } from "../../config/firebase";
import { stateNames } from "../../publicFunctions/publicHelpers/helpers";
import SubmitStory from "./SubmitStory";
import { DEFAULT_DRAWS } from "../../publicFunctions/publicHelpers/promptTypes";
import ShareOnSocials from "./ShareOnSocials";

function FirstStage({
  loadedGameState,
  promptTypes,
  userInfo,
  state,
  refreshGameState,
  lockedInInfo,
  handlePrematureSubmit,
  wordLimit,
  socialShare,
  openLogInHandler,
}) {
  const [genrePrompt, setGenrePrompt] = useState(new CardState("Genre"));
  const [prompt1, setPrompt1] = useState(new CardState(""));
  const [prompt2, setPrompt2] = useState(new CardState(""));
  const [message, setMessage] = useState("");
  const [drawControlsLocked, setDrawControlsLocked] = useState(true);
  const [isWorking, setIsWorking] = useState(false);
  const [forceLockCards, setForceLockCards] = useState(false);
  const [genreDefinition, setGenreDefinition] = useState("");
  const [showDoNotShareWarning, setShowDoNotShareWarning] = useState(false);

  const drawable = () => {
    let hasEnoughRegularDraws = false;
    if (loadedGameState) {
      const promptsUnlocked =
        !prompt1.isLocked && !prompt2.isLocked
          ? 2
          : !prompt1.isLocked || !prompt2.isLocked
          ? 1
          : 0;
      if (promptsUnlocked <= loadedGameState.draws) {
        hasEnoughRegularDraws = true;
      } else if (loadedGameState.draws === 1) {
        setMessage(
          "You're down to your last regular draw! " +
            "Please lock one of your prompts (not including Genre)."
        );
      }
    }

    return state === stateNames.INITIALIZED &&
      loadedGameState &&
      (loadedGameState.genreDraws || loadedGameState.draws) &&
      (!genrePrompt.isLocked || !prompt1.isLocked || !prompt2.isLocked) &&
      hasEnoughRegularDraws &&
      !loadedGameState.storyId
      ? true
      : false;
  };

  useEffect(() => {
    if (
      promptTypes &&
      loadedGameState &&
      loadedGameState.cards &&
      state !== stateNames.NOT_STARTED
    ) {
      // populate cards based on game state
      const tempGenreCard = new CardState(loadedGameState.cards.genre.type);
      tempGenreCard.definition = loadedGameState.cards.genre.definition;
      const tempPrompt1Card = new CardState(loadedGameState.cards.prompt1.type);
      const tempPrompt2Card = new CardState(loadedGameState.cards.prompt2.type);
      tempGenreCard.injectCardWithDataFromServer(loadedGameState.cards.genre);
      tempPrompt1Card.injectCardWithDataFromServer(
        loadedGameState.cards.prompt1
      );
      tempPrompt2Card.injectCardWithDataFromServer(
        loadedGameState.cards.prompt2
      );
      if (state === stateNames.INITIALIZED) {
        const genreHardLocked =
          !loadedGameState.genreDraws || loadedGameState.storyId ? true : false;
        tempGenreCard.setHardLocked(genreHardLocked);
        const promptsHardLocked =
          !loadedGameState.draws || loadedGameState.storyId ? true : false;
        tempPrompt1Card.setHardLocked(promptsHardLocked);
        tempPrompt2Card.setHardLocked(promptsHardLocked);
        setGenreDefinition(tempGenreCard.definition);
      }
      setGenrePrompt(tempGenreCard);
      setPrompt1(tempPrompt1Card);
      setPrompt2(tempPrompt2Card);
      setShowDoNotShareWarning(true);
    } else if (
      (promptTypes && !loadedGameState) ||
      (promptTypes && state === stateNames.NOT_STARTED)
    ) {
      setGenrePrompt(new CardState(promptTypes[0]));
      setPrompt1(new CardState(promptTypes[1]));
      setPrompt2(new CardState(promptTypes[2]));
      setDrawControlsLocked(true);
    }
  }, [promptTypes, loadedGameState]);

  useEffect(() => {
    if (loadedGameState && loadedGameState.cards) {
      setDrawControlsLocked(!drawable());
    }
  }, [genrePrompt, prompt1, prompt2]);

  useEffect(() => {
    if (!userInfo?.isStillCheckingLogin() && !userInfo?.isLoggedIn()) {
      setMessage("Please Log In");
      openLogInHandler("login");
    } else {
      setMessage("");
    }
  }, [userInfo]);

  const handleRedraw = async () => {
    if (!loadedGameState) return;
    setDrawControlsLocked(true);
    try {
      setIsWorking(true);
      setMessage("");
      const results = await redrawCards({
        redrawArray: [
          !genrePrompt.isLocked,
          !prompt1.isLocked,
          !prompt2.isLocked,
        ],
        battleId: loadedGameState.battleId,
      });
      if (results.data.success) {
        refreshGameState(results.data.updatedGameState).then(() => {
          setIsWorking(false);
        });
      } else {
        setMessage(
          "Hmmm... that didn't seem to work. Your cards were not redrawn. " +
            "Please refresh the page and try again in a few minutes."
        );
        setIsWorking(false);
      }
    } catch (err) {
      console.log(err);
      setMessage(
        "Hmmm... that didn't seem to work. Your cards were likely not redrawn. " +
          "Please refresh the page and try again in a few minutes."
      );
    }
  };

  const requestUnlock = (position) => {
    const currentCard =
      position === 0 ? genrePrompt : position === 1 ? prompt1 : prompt2;
    const isGenre = position === 0 ? true : false;

    if (currentCard.isHardLocked) {
      if (
        state === stateNames.INITIALIZED &&
        !forceLockCards &&
        loadedGameState
      ) {
        if (loadedGameState.storyId) {
          setMessage(
            "Your cards are locked because you have submitted a draft of your story."
          );
        } else if (!loadedGameState.genreDraws && !loadedGameState.draws) {
          setMessage("You're all out of draws!");
        } else if (isGenre && !loadedGameState.genreDraws) {
          setMessage("Genre can only be redrawn once.");
        } else if (!loadedGameState.draws) {
          setMessage("You have used up your draws for these cards.");
        }
      }
      return;
    }
    const tempCard = currentCard.clone();
    tempCard.setLocked(!tempCard.isLocked);
    if (position === 0) {
      setGenrePrompt(tempCard);
    } else if (position === 1) {
      setPrompt1(tempCard);
    } else if (position === 2) {
      setPrompt2(tempCard);
    }
    setMessage("");
  };

  const handleChangeOfMessage = (msg) => {
    setMessage(msg);
  };

  const handleForceLockCards = (lock) => {
    setForceLockCards(lock);
  };

  const capturePrematureSubmit = () => {
    setForceLockCards(true);
    genrePrompt.setHardLocked(true);
    prompt1.setHardLocked(true);
    prompt2.setHardLocked(true);
    setMessage("");
    handlePrematureSubmit();
  };

  const genreDrawAmount = genrePrompt.isLocked ? "" : "-1";
  const drawAmount =
    !prompt1.isLocked && !prompt2.isLocked
      ? "-2"
      : !prompt1.isLocked || !prompt2.isLocked
      ? "-1"
      : "";

  const showShareSocials =
    userInfo?.isLoggedIn() &&
    state === stateNames.NOT_STARTED &&
    loadedGameState &&
    loadedGameState.draws < DEFAULT_DRAWS + 1
      ? true
      : false;

  return (
    <div className="first-stage-container">
      {showDoNotShareWarning ? (
        <div className="yellow-background margin-top-bottom-md">
          <div className="max-width--readable-center--larger">
            <p className="padding description--small bold center-text">
              Reminder: Do <em>not</em> share your prompts or the title of your
              story until <em>after</em> peer judging. Publicly revealing
              anything about your story may result in disqualification from the
              Battle. You may only reveal your Genre and beta-swap with others
              in your same Genre. Thank you and have fun :)
            </p>
          </div>
        </div>
      ) : null}
      <div className="battle-cards">
        <BattleCard
          cardState={genrePrompt}
          position={0}
          requestUnlock={requestUnlock}
          isWorking={isWorking}
          cardIsBeingRedrawn={isWorking && !genrePrompt.isLocked}
          forceLockCards={forceLockCards}
        />
        <BattleCard
          cardState={prompt1}
          position={1}
          requestUnlock={requestUnlock}
          isWorking={isWorking}
          cardIsBeingRedrawn={isWorking && !prompt1.isLocked}
          forceLockCards={forceLockCards}
        />
        <BattleCard
          cardState={prompt2}
          position={2}
          requestUnlock={requestUnlock}
          isWorking={isWorking}
          cardIsBeingRedrawn={isWorking && !prompt2.isLocked}
          forceLockCards={forceLockCards}
        />
      </div>
      <p className="description center-text margin-left-right-small description--smallerMobile">
        {message}
      </p>
      {showShareSocials ? <ShareOnSocials socialShare={socialShare} /> : null}
      {lockedInInfo.isLockedIn ? (
        <SubmitStory
          lockedInInfo={lockedInInfo}
          wordLimit={wordLimit}
          refreshGameState={refreshGameState}
          battleId={loadedGameState ? loadedGameState.battleId : ""}
          savedStoryId={loadedGameState ? loadedGameState.storyId : ""}
        />
      ) : (
        <DrawPromptsControls
          loadedGameState={loadedGameState}
          drawControlsLocked={drawControlsLocked}
          handleRedraw={handleRedraw}
          genreDrawAmount={genreDrawAmount}
          drawAmount={drawAmount}
          handleChangeOfMessage={handleChangeOfMessage}
          handleForceLockCards={handleForceLockCards}
          lockedInInfo={lockedInInfo}
          handlePrematureSubmit={capturePrematureSubmit}
        />
      )}
      {lockedInInfo && !lockedInInfo.battleNotStarted ? (
        <div className="simple-divider padding-top--sm"></div>
      ) : (
        <div></div>
      )}
      <p className="description center-text margin-top-bottom-sm padding">
        <strong>
          {(!forceLockCards || genrePrompt.isLocked) &&
          genreDefinition &&
          loadedGameState &&
          (!isWorking || genrePrompt.isLocked)
            ? "Genre Definition"
            : ""}
        </strong>
        <br />
        {(!forceLockCards || genrePrompt.isLocked) &&
        loadedGameState &&
        (!isWorking || genrePrompt.isLocked)
          ? genreDefinition
          : ""}
      </p>
    </div>
  );
}

export default FirstStage;
