import Lottie from "react-lottie-player";
import loadingAnimation from "../../img/animations/simpleLoading.json";
import { useEffect, useState } from "react";
import { updateMyPrivateUserInfo } from "../../publicFunctions/registerHandler";
import QuestionAndAnswer from "../rules/QuestionAndAnswer";

function Settings({ myProfile, refreshMyProfile }) {
  const [hideContentWarnings, setHideContentWarnings] = useState(
    myProfile?.settings?.hideContentWarnings
      ? myProfile.settings.hideContentWarnings
      : false
  );
  const [signedUpAsSpartan, setSignedUpAsSpartan] = useState(
    myProfile?.settings?.signedUpAsSpartan
      ? myProfile.settings.signedUpAsSpartan
      : false
  );
  const [donateSpartanSiteCredit, setDonateSpartanSiteCredit] = useState(
    myProfile?.settings?.donateSpartanSiteCredit
      ? myProfile.settings.donateSpartanSiteCredit
      : false
  );
  const [subscribeToNewsletter, setSubscribeToNewsletter] = useState(
    myProfile?.notificationPreferences?.newsletter
      ? myProfile.notificationPreferences.newsletter
      : false
  );

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  useEffect(() => {
    if (myProfile) {
      if (myProfile.settings) {
        setHideContentWarnings(
          myProfile.settings.hideContentWarnings ? true : false
        );
        setSignedUpAsSpartan(
          myProfile.settings.signedUpAsSpartan ? true : false
        );
        setDonateSpartanSiteCredit(
          myProfile.settings.donateSpartanSiteCredit ? true : false
        );
      }
      if (myProfile.notificationPreferences) {
        setSubscribeToNewsletter(
          myProfile.notificationPreferences.newsletter ? true : false
        );
      }
    }
  }, [myProfile]);

  const onHideContentWarnings = () => {
    setHideContentWarnings(!hideContentWarnings);
    setChangesMade(true);
    setErrorMessage("");
  };

  const onSignedUpAsSpartan = () => {
    setSignedUpAsSpartan(!signedUpAsSpartan);
    setChangesMade(true);
    setErrorMessage("");
  };

  const onDonateSpartanSiteCredit = () => {
    setDonateSpartanSiteCredit(!donateSpartanSiteCredit);
    setChangesMade(true);
    setErrorMessage("");
  };

  const onSubscribeToNewsletter = () => {
    setSubscribeToNewsletter(!subscribeToNewsletter);
    setChangesMade(true);
    setErrorMessage("");
  };

  const changeSettings = async (event) => {
    event.preventDefault();
    const userId = myProfile?.userId;
    if (!userId) {
      setErrorMessage(
        "Something went wrong. Please try refreshing the page and try again."
      );
      return;
    }

    setIsLoading(true);
    try {
      const tempNewsletter = subscribeToNewsletter;
      const tempHideContentWarnings = hideContentWarnings;
      const tempSignedUpAsSpartan = signedUpAsSpartan;
      const tempDonateSpartanSiteCredit = donateSpartanSiteCredit;
      const success = await updateMyPrivateUserInfo(userId, {
        notificationPreferences: {
          newsletter: tempNewsletter,
        },
        settings: {
          hideContentWarnings: tempHideContentWarnings,
          signedUpAsSpartan: tempSignedUpAsSpartan,
          donateSpartanSiteCredit: tempDonateSpartanSiteCredit,
        },
      });
      if (success) {
        setErrorMessage("Success! Your settings have been updated.");
        setChangesMade(false);
        refreshMyProfile();
      } else {
        setErrorMessage(
          "Something went wrong. Please try refreshing the page and try again."
        );
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(
        "Hmmm that didn't seem to work. Please try refreshing the page and trying again. Contact admin@writingbattle.com if this continues to happen."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="simple-divider"></div>
      <p className="description--larger bold center-text margin-auto margin-top-bigger margin-bottom-sm">
        Settings
      </p>
      <div className="register-form">
        <div className="flex flex--gap-small flex--justify-start flex--align-center">
          <input
            className="checkbox"
            type="checkbox"
            id="hideContentWarnings"
            value="hideContentWarnings"
            checked={hideContentWarnings}
            onChange={onHideContentWarnings}
          />
          <label className="center-vert" htmlFor="hideContentWarnings">
            Hide All Content Warnings
          </label>
        </div>
        <div className="flex flex--gap-small flex--justify-start flex--align-center">
          <input
            className="checkbox"
            type="checkbox"
            id="subscribeToNewsletter"
            value="subscribeToNewsletter"
            checked={subscribeToNewsletter}
            onChange={onSubscribeToNewsletter}
          />
          <label className="center-vert" htmlFor="subscribeToNewsletter">
            Subscribe to Newsletter / Battle Reminders
          </label>
        </div>
        <div className="flex flex--gap-small flex--justify-start flex--align-center">
          <input
            className="checkbox"
            type="checkbox"
            id="spartanSignUp"
            value="signedUpAsSpartan"
            checked={signedUpAsSpartan}
            onChange={onSignedUpAsSpartan}
          />
          <label className="center-vert" htmlFor="spartanSignUp">
            Yes, sign me up as a *Spartan!
          </label>
        </div>
        <div className="flex flex--gap-small flex--justify-start flex--align-center">
          <input
            className="checkbox"
            type="checkbox"
            id="donateSiteCredit"
            value="donateSiteCredit"
            checked={donateSpartanSiteCredit}
            onChange={onDonateSpartanSiteCredit}
          />
          <label className="center-vert" htmlFor="donateSiteCredit">
            Donate credit earned from Spartan Duels**
          </label>
        </div>
        {errorMessage ? (
          <p className="description error-text">{errorMessage}</p>
        ) : null}
        <button
          className="btn btn--register"
          disabled={isLoading || !changesMade}
          onClick={changeSettings}
        >
          Update Settings
          <div className={isLoading ? "simple-loader" : "hidden"}>
            <Lottie
              loop
              animationData={loadingAnimation}
              speed={1.5}
              play
            ></Lottie>
          </div>
        </button>
      </div>
      <br />
      <br />
      <QuestionAndAnswer
        question={"* What is a Spartan?"}
        answer={
          "Spartans are folks from the community that may be asked to judge additional Duels during the Final Showdown of a Battle. It's a lottery system, with preference given to veteran Battlers. No feedback is given during a Final Showdown Spartan Duel, so they do not take long to complete, and you get between $1 and $2 in Writing Battle site credit for every extra Duel that you judge. There's also no obligation to do them! You will be sent an email and you can choose to accept the Duel at the time the email is received, or ignore the email entirely. There is no obligation to accept a Duel when one is sent to you and no penalty for not accepting the Duel."
        }
        widthOverride={true}
      />
      <br />
      <QuestionAndAnswer
        question={"** Where would my Spartan credit be donated?"}
        answer={
          "Spartans earn site credit every time they complete Tie-breakers or Duels during the Final Showdown. Check the box if you would like to contribute that site credit to a pool of credit that participants in need can draw from if they cannot afford to Battle."
        }
        widthOverride={true}
      />
    </div>
  );
}

export default Settings;
