import { useEffect, useState } from "react";
import BattleCard from "../battle/BattleCard";
import CardState from "../../publicFunctions/clientClasses/CardState";
import { IonIcon } from "@ionic/react";
import { caretUpOutline } from "ionicons/icons";
import {
  MAX_TEXT_PROMPT_LENGTH,
  MAX_TYPE_PROMPT_LENGTH,
  createCardWrapperForTavernCard,
} from "../../publicFunctions/tavernHandler";
import { pageScrollSettings } from "../../publicFunctions/publicHelpers/helpers";

function PromptSelector({
  componentName,
  highlight,
  clickHandler,
  reference,
  loadedCard,
  promptCardHandler,
  loadedStory,
  raidObject,
}) {
  const [type, setType] = useState("");
  const [text, setText] = useState("");
  const [cardId, setCardId] = useState("");
  const [displayCardState, setDisplayCardState] = useState(
    componentName === "first" ? new CardState("Genre") : new CardState("")
  );
  const [cardIdError, setCardIdError] = useState(true);
  const [useDefaultType, setUseDefaultType] = useState(false);
  const [useDefaultText, setUseDefaultText] = useState(false);
  // initializing, cardsAlreadySet, complete
  const [initState, setInitState] = useState("initializing");

  useEffect(() => {
    if (raidObject?.cards?.length >= 3 && (!type || !text)) {
      let card;
      if (componentName === "first") {
        card = raidObject.cards[0];
      } else if (componentName === "second") {
        card = raidObject.cards[1];
      } else if (componentName === "third") {
        card = raidObject.cards[2];
      }
      if (card && card.type && card.text) {
        setType(card.type);
        setText(card.text);
      }
    }
  }, [raidObject]);

  useEffect(() => {
    if (!cardId) {
      setCardIdError(true);
    } else {
      setCardIdError(false);
      if (reference?.current) {
        reference.current.scrollIntoView(pageScrollSettings);
      }
    }
  }, [cardId]);

  useEffect(() => {
    if (loadedCard?.id && loadedCard.imageFileName) {
      const tempCardState = displayCardState;
      const cardWrapper = createCardWrapperForTavernCard({
        id: loadedCard.id,
        imageFileName: loadedCard.imageFileName,
      });
      const tempText =
        loadedCard.text && useDefaultText ? loadedCard.text : text;
      const tempType =
        loadedCard.type && useDefaultType ? loadedCard.type : type;
      cardWrapper.text = tempText;
      cardWrapper.type = tempType;
      tempCardState.injectCardWithDataFromServer(cardWrapper);
      setDisplayCardState(tempCardState);
      setCardId(loadedCard.id);
    }
  }, [loadedCard]);

  // do this once and this is just for the text and type
  useEffect(() => {
    if (
      (loadedStory?.storyId || loadedStory?.fromGenerator) &&
      initState === "initializing"
    ) {
      if (loadedStory.cards) {
        if (componentName === "first") {
          const card = loadedStory.cards.genre;
          setType(card.type ? card.type : "");
          setText(card.text ? card.text : "");
        }
        if (componentName === "second") {
          const card = loadedStory.cards.prompt1;
          setType(card.type ? card.type : "");
          setText(card.text ? card.text : "");
        }
        if (componentName === "third") {
          const card = loadedStory.cards.prompt2;
          setType(card.type ? card.type : "");
          setText(card.text ? card.text : "");
        }
        setInitState("cardsAlreadySet");
      }
    } else if (
      loadedStory &&
      !loadedStory.storyId &&
      initState === "initializing"
    ) {
      setInitState("cardsAlreadySet");
    } else {
      // TODO: (important) CLEAR EVERYTHING
    }
  }, [loadedStory, initState]);

  useEffect(() => {
    if (
      useDefaultText &&
      displayCardState &&
      loadedCard?.id &&
      loadedCard.text
    ) {
      const tempCardState = displayCardState.clone();
      tempCardState.text = loadedCard.text;
      setDisplayCardState(tempCardState);
    } else if (
      !useDefaultText &&
      displayCardState &&
      loadedCard?.id &&
      loadedCard.text
    ) {
      const tempCardState = displayCardState.clone();
      tempCardState.text = text;
      setDisplayCardState(tempCardState);
    }
  }, [useDefaultText]);

  useEffect(() => {
    if (
      useDefaultType &&
      displayCardState &&
      loadedCard?.id &&
      loadedCard.type
    ) {
      const tempCardState = displayCardState.clone();
      tempCardState.type = loadedCard.type;
      setDisplayCardState(tempCardState);
    } else if (
      !useDefaultType &&
      displayCardState &&
      loadedCard?.id &&
      loadedCard.type
    ) {
      const tempCardState = displayCardState.clone();
      tempCardState.type = type;
      setDisplayCardState(tempCardState);
    }
  }, [useDefaultType]);

  useEffect(() => {
    if (displayCardState && loadedCard?.id) {
      const tempCardState = displayCardState.clone();
      let somethingChanged = false;
      if (tempCardState.text !== text && !useDefaultText) {
        tempCardState.text = text;
        somethingChanged = true;
      }
      if (tempCardState.type !== type && !useDefaultType) {
        tempCardState.type = type;
        somethingChanged = true;
      }
      if (somethingChanged) {
        setDisplayCardState(tempCardState);
      }
    }
  }, [text, type, useDefaultText, useDefaultType]);

  // update prompt card handler
  useEffect(() => {
    if (initState === "complete") {
      if (loadedCard?.id) {
        let definition = "";
        if (
          componentName === "first" &&
          useDefaultType &&
          useDefaultText &&
          loadedCard.definition
        ) {
          definition = loadedCard.definition;
        }
        promptCardHandler(
          componentName,
          loadedCard.id,
          useDefaultType ? loadedCard.type : type,
          useDefaultText ? loadedCard.text : text,
          definition
        );
      }
    } else if (initState === "cardsAlreadySet" && loadedStory?.storyId) {
      // get the definition from the genre card (if one exists)
      let definition = "";
      if (componentName === "first" && loadedStory?.cards?.genre?.definition) {
        definition = loadedStory.cards.genre.definition;
      }
      if (componentName === "first") {
        promptCardHandler(
          componentName,
          loadedStory.cards.genre.id,
          loadedStory.cards.genre.type,
          loadedStory.cards.genre.text,
          definition
        );
      } else if (componentName === "second") {
        promptCardHandler(
          componentName,
          loadedStory.cards.prompt1.id,
          loadedStory.cards.prompt1.type,
          loadedStory.cards.prompt1.text,
          definition
        );
      } else if (componentName === "third") {
        promptCardHandler(
          componentName,
          loadedStory.cards.prompt2.id,
          loadedStory.cards.prompt2.type,
          loadedStory.cards.prompt2.text,
          definition
        );
      }
      setInitState("complete");
    } else if (initState === "cardsAlreadySet") {
      setInitState("complete");
    }
  }, [text, type, loadedCard, useDefaultText, useDefaultType, initState]);

  const defaultTypeCheckboxName = "defaultTypeCheckbox_" + componentName;
  const defaultTextCheckboxName = "defaultTextCheckbox_" + componentName;
  const defaultTypeInputName = "defaultTypeInput_" + componentName;
  const defaultTextInputName = "defaultTextInput_" + componentName;

  return (
    <div ref={reference} className="prompt-selector-container">
      {loadedCard ? (
        <>
          {!raidObject ? (
            <div className="flex flex--gap-smaller padding--small align-items--center">
              <input
                id={defaultTypeCheckboxName}
                className="checkbox"
                type="checkbox"
                value={defaultTypeCheckboxName}
                checked={useDefaultType}
                onChange={(event) => {
                  setUseDefaultType(!useDefaultType);
                }}
              />
              <label
                htmlFor={defaultTypeCheckboxName}
                className="description--smaller"
              >
                Use default card type?
              </label>
            </div>
          ) : null}
          {!raidObject && !useDefaultType ? (
            <form
              className="register-form"
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <div className="margin-left-right-tiny">
                <input
                  id={defaultTypeInputName}
                  type="text"
                  placeholder="Prompt Type"
                  required
                  maxLength={MAX_TYPE_PROMPT_LENGTH}
                  value={type}
                  onChange={(event) => {
                    setType(event.target.value);
                  }}
                />
              </div>
            </form>
          ) : null}
        </>
      ) : null}
      <div className="cursor--pointer" role="button" onClick={clickHandler}>
        {cardIdError && !highlight ? (
          <p className="description--small center-text bold">Select card</p>
        ) : null}
        <div
          className={
            "prompt-selector flex justify-content--center" +
            (highlight ? " prompt-selector--highlighted" : "") +
            (cardIdError && !highlight ? " prompt-selector--error" : "")
          }
        >
          <BattleCard
            cardState={displayCardState}
            position={0}
            requestUnlock={null}
            isWorking={false}
            cardIsBeingRedrawn={false}
            forceLockCards={true}
            makeThisAButton={true}
            ownedBorder={false}
            promptSelector={true}
          />
        </div>
        {highlight ? (
          <div className="flex margin-auto justify-content--center">
            <IonIcon
              className="social-icon--large margin--none"
              icon={caretUpOutline}
            />
          </div>
        ) : null}
      </div>
      {loadedCard ? (
        <>
          {!raidObject && !useDefaultText ? (
            <form
              className="register-form"
              onSubmit={(event) => {
                event.preventDefault();
              }}
            >
              <div className="margin-left-right-tiny">
                <input
                  id={defaultTextInputName}
                  type="text"
                  placeholder="Prompt Text"
                  required
                  maxLength={MAX_TEXT_PROMPT_LENGTH}
                  value={text}
                  onChange={(event) => {
                    setText(event.target.value);
                  }}
                />
              </div>
            </form>
          ) : null}
          {!raidObject ? (
            <div className="flex flex--gap-smaller padding--small align-items--center">
              <input
                id={defaultTextCheckboxName}
                className="checkbox"
                type="checkbox"
                value={defaultTextCheckboxName}
                checked={useDefaultText}
                onChange={(event) => {
                  setUseDefaultText(!useDefaultText);
                }}
              />
              <label
                htmlFor={defaultTextCheckboxName}
                className="description--smaller"
              >
                Use default card text?
              </label>
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default PromptSelector;
