import { IonIcon } from "@ionic/react";
import {
  flashOutline,
  closeCircle,
  checkmarkCircleOutline,
} from "ionicons/icons";

import BackOfCardTiny from "../../img/red_back_tiny.webp";
import BackOfGenreTiny from "../../img/blue_back_tiny.webp";
import { useEffect, useState } from "react";

function DrawPromptsControls({
  loadedGameState,
  drawControlsLocked,
  handleRedraw,
  genreDrawAmount,
  drawAmount,
  handleChangeOfMessage,
  handleForceLockCards,
  lockedInInfo,
  handlePrematureSubmit,
}) {
  const [genreDrawText, setGenreDrawText] = useState("0");
  const [drawText, setDrawText] = useState("0");
  const [showYouSure, setShowYouSure] = useState(false);
  const [interestedInSubmit, setInterestedInSubmit] = useState(false);

  useEffect(() => {
    if (loadedGameState?.infDraws) {
      setGenreDrawText("\u221e");
      setDrawText("\u221e");
    } else if (loadedGameState) {
      setGenreDrawText(
        loadedGameState.genreDraws
          ? loadedGameState.genreDraws.toString(10)
          : "0"
      );
      setDrawText(
        loadedGameState.draws ? loadedGameState.draws.toString(10) : "0"
      );
    } else {
      setGenreDrawText("0");
      setDrawText("0");
      setShowYouSure(false);
    }
  }, [loadedGameState]);

  // we want to lock the controls when showing you sure
  drawControlsLocked = showYouSure ? true : drawControlsLocked;

  const handleShowYouSure = (show) => {
    handleForceLockCards(show);
    handleChangeOfMessage("");
    setShowYouSure(show);
  };

  const handleInterestedInSubmittingEarly = (interested) => {
    setInterestedInSubmit(interested);
  };

  const lockedInfoElement = (
    <div className="locked-in-date">
      {!interestedInSubmit ? (
        <div>
          <p className="description center-text">
            {"Cards will be automatically locked-in on " +
              lockedInInfo.dateText}
          </p>
          <p className="description--small center-text">
            <strong>
              Ready to submit your story? Click{" "}
              <span
                className="prem-req clickable-text"
                onClick={() => handleInterestedInSubmittingEarly(true)}
              >
                here
              </span>
              .
            </strong>
          </p>
        </div>
      ) : (
        <div></div>
      )}
      {!interestedInSubmit ? (
        <div></div>
      ) : (
        <p className="description--small center-text">
          As soon as you submit a draft of your story, you will not be able to
          redraw your prompts. Wait...
          <span
            className="prem-req clickable-text"
            onClick={() => handleInterestedInSubmittingEarly(false)}
          >
            nevermind
          </span>
          .
          <br />
          <br />
          <span
            className="prem-req clickable-text"
            onClick={handlePrematureSubmit}
          >
            <strong>Yup, I understand</strong>
          </span>
          .
        </p>
      )}
    </div>
  );

  return (
    <div className="draw-prompts-controls-container">
      <div className="draw-container">
        <p className="asset-title">
          Genre
          <br />
          Draw
        </p>
        <p className="asset-amount">{genreDrawText}</p>
        <p className="draw-amount">{genreDrawAmount}</p>
        <img className="mini-card" src={BackOfGenreTiny}></img>
      </div>
      <div className="draw-container">
        <p className="asset-title asset-title--expand-height">Draws</p>
        <p className="asset-amount">{drawText}</p>
        <p className="draw-amount">{drawAmount}</p>
        <img className="mini-card" src={BackOfCardTiny}></img>
      </div>
      <div className="flex flex--direction-column redraw-controls justify-content--center">
        <button
          className="btn btn--outline redraw-button"
          onClick={() => handleShowYouSure(true)}
          disabled={drawControlsLocked}
        >
          <div className="redraw-button-container">
            Redraw
            <IonIcon className="social-icon" icon={flashOutline} />
          </div>
        </button>
        {showYouSure ? (
          <div className="redraw--youSure">
            <p className="description bold">Are you sure?</p>
            <IonIcon
              onClick={() => {
                handleShowYouSure(false);
                handleRedraw();
              }}
              className="im-sure"
              icon={checkmarkCircleOutline}
            />
            <IonIcon
              onClick={() => {
                handleShowYouSure(false);
              }}
              className="not-sure"
              icon={closeCircle}
            />
          </div>
        ) : null}
      </div>
      {lockedInInfo.showText && lockedInInfo.dateText && loadedGameState ? (
        lockedInfoElement
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default DrawPromptsControls;
